import { action, thunk, Action, Thunk } from 'easy-peasy'

import {
  CustomerGroup,
  CampaignGroup,
  ChannelGroup,
  BrandGroup,
  SupplierGroup,
  ProductCategoryGroup,
  StoreGroup,
  PhysicalStoreGroup,
  ProductCategoryGroupPCM,
} from 'DimensionsFiltersTypes'
// import { AUDIENCE_SEGMENT_DIMENSIONS_WITH_TIME_RANGE } from 'Pages/Audiences/Detail/Components/Criteria/constants'
import { getDimensions } from 'Services/dimensions'
import {
  CAMPAIGN_GROUP,
  CHANNEL_GROUP,
} from 'Containers/DimensionFilters/DimensionGroups/constants'
import { getStores } from 'Services/customer-segments'
import { Tree } from 'antd'
export interface States {
  customerGroup: CustomerGroup[] | undefined
  channelGroup: ChannelGroup[] | undefined
  brandGroup: BrandGroup[] | undefined
  supplierGroup: SupplierGroup[] | undefined
  campaignGroup: CampaignGroup[] | undefined
  storeGroup: StoreGroup[] | undefined
  physicalStoreGroup: PhysicalStoreGroup[] | undefined
  productCategoryGroup: ProductCategoryGroup[] | undefined
  productCategoryGroupPCM: ProductCategoryGroupPCM[] | undefined
}

export interface StoreProps extends States {
  setCustomerGroup: Action<StoreProps, CustomerGroup[]>
  setChannelGroup: Action<StoreProps, ChannelGroup[]>
  setBrandGroup: Action<StoreProps, BrandGroup[]>
  setSupplierGroup: Action<StoreProps, SupplierGroup[]>
  setProductCategoryGroup: Action<StoreProps, ProductCategoryGroup[]>
  setProductCategoryGroupPCM: Action<StoreProps, ProductCategoryGroupPCM[]>
  setStoreGroup: Action<StoreProps, StoreGroup[]>
  setPhysicalStoreGroup: Action<StoreProps, PhysicalStoreGroup[]>
  getDimensionGroups: Thunk<StoreProps, { [name: string]: { page?: string } }>
}

const initState = {
  customerGroup: [],
  channelGroup: [],
  brandGroup: [],
  supplierGroup: [],
  productCategoryGroup: [],
  productCategoryGroupPCM: [],
  storeGroup: [],
  physicalStoreGroup: [],
  campaignGroup: CAMPAIGN_GROUP,
}

const store: StoreProps = {
  ...initState,

  setCustomerGroup: action((state, data) => {
    state.customerGroup = data
  }),
  setChannelGroup: action((state, data) => {
    state.channelGroup = data
  }),
  setBrandGroup: action((state, data) => {
    state.brandGroup = data
  }),
  setSupplierGroup: action((state, data) => {
    state.supplierGroup = data
  }),
  setProductCategoryGroup: action((state, data) => {
    state.productCategoryGroup = data
  }),
  setProductCategoryGroupPCM: action((state, data) => {
    state.productCategoryGroupPCM = data
  }),
  setStoreGroup: action((state, data) => {
    state.storeGroup = data
  }),
  setPhysicalStoreGroup: action((state, data) => {
    state.physicalStoreGroup = data
  }),
  getDimensionGroups: thunk(async (action, list) => {
    try {
      const checkPath = window.location.pathname.split('/')[2]
      if (['customers', 'marketing-campaigns', undefined].includes(checkPath)) {
        if (list.customer) {
          const customerResp = await getDimensions({
            group: 'customer',
            page: list?.customer?.page,
          })
          // console.log('customerResp ', customerResp)

          action.setCustomerGroup(customerResp.data)
        }
      }
      if (['segments'].includes(checkPath)) {
        if (list.customer) {
          const customerResp = await getDimensions({
            group: 'customer',
            page: list?.customer?.page,
          })
          // console.log('customerResp ', customerResp)

          action.setCustomerGroup(customerResp.data)
        }
      }
      if (['sale-campaigns', 'audience', undefined].includes(checkPath)) {
        if (list.saleChannel) {
          const saleChannelResponse = await getDimensions({
            group: 'sale_channel',
            page:
              window.location.pathname.split('/')[2] == 'products'
                ? 'product_analytic'
                : list?.saleChannel?.page,
          })
          action.setChannelGroup(saleChannelResponse.data)
        }
      }
      if (['products'].includes(checkPath)) {
        if (list.saleChannel) {
          const saleChannelResponse = await getDimensions({
            group: 'sale_channel',
            page:
              window.location.pathname.split('/')[2] == 'products'
                ? 'product_analytic'
                : list?.saleChannel?.page,
          })
          action.setChannelGroup(saleChannelResponse.data)
        }

        const brandFilterResponse = await getDimensions({
          group: 'brand',
          page: 'product_analytic',
        })
        action.setBrandGroup(brandFilterResponse.data)

        const supplierFilterResponse = await getDimensions({
          group: 'supplier',
          page: 'product_analytic',
        })
        action.setSupplierGroup(supplierFilterResponse.data)

        const productCategoryFilterResponse = await getDimensions({
          group: 'product_category',
          page: 'product_analytic',
        })
        action.setProductCategoryGroup(productCategoryFilterResponse.data)

        const productCategoryPCMFilterResponse = await getDimensions({
          group: 'product_category',
          page: 'product_analytic',
          source: 'pcm',
        })
        action.setProductCategoryGroupPCM(productCategoryPCMFilterResponse.data)
        const physicalStoreGroupFilterResponse = await getDimensions({
          group: 'physical_store',
          page: 'product_analytic',
        })

        action.setPhysicalStoreGroup(physicalStoreGroupFilterResponse.data)
      }

      const storeFilterResponse = await getStores()
      action.setStoreGroup(storeFilterResponse.data.tree_store)
      // const channelResp = await getDimensions({ group: 'channel' })
      // const campaignResp = await getDimensions({ group: 'campaign' })
    } catch (err) {
      throw err
    }
  }),
}

export default store
