import React from 'react'
import { action, thunk } from 'easy-peasy'
import moment from 'moment'
import cjAxios from 'Utils/cj-axios'
import { message } from 'antd'
import { DEFAULT_NODE } from 'Containers/CustomerJourneyDesign/constants'
import _, { camelCase } from 'lodash'

export let flattenNodes = []

const { error } = message
const initState = {
  name: 'Customer Journey Name',
  status: '',
  description: '',
  time: [moment(), moment().add('month', 1)],
  CJTree: DEFAULT_NODE,
  isFetchingCJ: false,
  shouldDraw: false,
  isCJModified: false,
}

export default {
  ...initState,
  setCJStates: action((state, { key, value }) => {
    state[key] = value
  }),
  setShouldDraw: action((state, payload) => {
    state.shouldDraw = payload
  }),
  setIsFetchingCJ: action((state, payload) => {
    state.isFetchingCJ = payload
  }),
  toggleLoading: action((state) => {
    state.loading = !state.loading
  }),
  toggleActionLoading: action((state) => {
    state.actionLoading = !state.actionLoading
  }),
  resetState: action((state) => ({ ...initState })),
  resetNodeConfigStates: thunk(async (actions, _, { getStoreActions }) => {
    const storeActions = getStoreActions()
    await storeActions.campaign.audience.resetState()
    await storeActions.segmentation.resetState()
    await storeActions.campaign.creativeContents.clearPreviewData()
  }),
  getCJ: thunk(async (actions, id, { getState, getStoreActions }) => {
    const state = getState()
    const storeActions = getStoreActions()
    const resp = await cjAxios.get(`customer-journeys/${id}`)

    if (resp.errors) return

    if (resp.status === 200) {
      const {
        name,
        description,
        status,
        start_time,
        end_time,
        id,
        reached,
        passed,
        drop_off,
        in_progressing,
      } = resp.data
      actions.setCJStates({ key: 'id', value: id })
      actions.setCJStates({ key: 'name', value: name })
      actions.setCJStates({ key: 'description', value: description })
      actions.setCJStates({ key: 'status', value: status })
      actions.setCJStates({ key: 'time', value: [start_time, end_time] })
      await storeActions.CJAnalytics.setCJAnalyticsStates({
        key: 'cj_metrics',
        value: [
          {
            key: 'reached',
            value: reached,
            label: 'Total reached',
          },
          {
            key: 'passed',
            value: passed,
            label: 'Total passed',
          },
          {
            key: 'drop_off',
            value: drop_off,
            label: 'Total drop-off',
          },
          {
            key: 'in_progressing',
            value: in_progressing,
            label: 'Total in progress',
          },
        ],
      })
    }

    const CJTreeResp = await cjAxios.get(`customer-journeys/${id}/nodes`)

    // rename keys in nested object
    const renameKeys = (obj, keysMap) => {
      return _.transform(obj, function (result, value, key) {
        // transform to a new object
        var currentKey = keysMap[key] || key // if the key is in keysMap use the replacement, if not use the original key

        result[currentKey] =
          _.isObject(value) && currentKey != 'value' ? renameKeys(value, keysMap) : value // if the key is an object run it through the inner function - replaceKeys
      })
    }

    if (CJTreeResp.status === 200) {
      const formattedCJTree = renameKeys(CJTreeResp.data, {
        childrenNodes: 'childrenNodes',
        subtype: 'nodeType',
        type: 'parentType',
        nodeValue: 'value',
        type: 'parentType',
      })

      CJTreeResp.data &&
        actions.setCJStates(
          {
            key: 'CJTree',
            value: formattedCJTree,
          },
          { key: 'shouldDraw', value: true }
        )
    }
    actions.setShouldDraw(true)
    actions.setIsFetchingCJ(false)
  }),

  createCJ: thunk(async (actions, _, { getState }) => {
    const { name, status, description, time } = getState()
    try {
      const resp = await cjAxios.post('/customer-journeys/', {
        name,
        status,
        description,
        // startTime: moment(time[0]).format('DD-MM-YYYY HH:mm:ss'),
        // endTime: moment(time[1]).format('DD-MM-YYYY HH:mm:ss'),
      })

      await actions.updateNode(resp.data.id)

      actions.setCJStates({ key: 'isCJModified', value: false })
      return { ok: true, id: resp.data.id }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),
  updateCJ: thunk(async (actions, id, { getState }) => {
    const { name, status, description, time, CJTree } = getState()
    try {
      const resp = await cjAxios.put(`/customer-journeys/${id}`, {
        name,
        status,
        description,
        // startTime: moment(time[0]).format('DD-MM-YYYY HH:mm:ss'),
        // endTime: moment(time[1]).format('DD-MM-YYYY HH:mm:ss'),
      })
      await actions.updateNode(resp.data.id)
      actions.setCJStates({ key: 'isCJModified', value: false })
      return { ok: true }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),
  deleteCJ: thunk(async (actions, id) => {
    try {
      await cjAxios.delete(`customer-journeys/${id}`)
      return { ok: true }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),
  updateNode: thunk(async (actions, id, { getState }) => {
    const computeSubtype = (node) => {
      if (
        ['wait', 'entrance', 'virtual', 'checkAudienceSegment', 'split', 'waitIn'].includes(
          node.nodeType
        )
      )
        return node.nodeType
      return node.parentType ? node.nodeType : null
    }

    try {
      await cjAxios.put(
        `customer-journeys/${id}/nodes`,
        flattenNodes.map((node) => ({
          nodeUuid: node.nodeUuid,
          // priority: node.priority || 0,
          type: node.parentType ? node.parentType : node.nodeType,
          subtype: computeSubtype(node),
          parentNode: node.parent_node_uuid,
          nodeValue: node.value,
          nodeAttribute: node.nodeAttribute,
          alias: node?.alias?.toString(),
          // customerJourneyId: id,
        }))
      )
      return { ok: true }
    } catch (err) {
      return Promise.reject(err)
    }
  }),
  setCJActions: thunk(async (actions, { id, action }) => {
    const resp = await cjAxios.post(`customer-journeys/${id}/action?action=${action}`)

    if (resp.status === 200) {
      return { ok: true, status: resp.data.status }
    }
    return { errors: resp.response.data }
  }),
}
